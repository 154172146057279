import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic7.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic8.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic9.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic10.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic11.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic12.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic13.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic14.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic15.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic16.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic17.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic18.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic19.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic20.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic21.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic22.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic23.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic24.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic25.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic26.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/05-july-2023/pic27.jpeg",
];

export default function GuruPurnima2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Guru Purnima Celebrations</h1>

        <p>
          Paying homage to the revered Gurus on the occasion of Guru Purnima,
          Chinmaya Vidyalaya, New Delhi, enlightened the students about the
          importance of Guru Purnima and the role of gurus in shaping their
          lives. The celebrations commenced with a talk wherein the significance
          of Guru Purnima in our culture and the value of expressing gratitude
          to our gurus was explained. <br />
          <br />
          Ms. Archana Soni, the Principal of the school, enlightened the
          students about the significance of respecting gurus and having faith
          in them. <br />
          <br />
          The celebrations proceeded with a solemn worship ceremony, known as
          the Paduka Puja, to honor all the gurus. Towards the culmination of
          the celebrations, 108 names of Pujya Gurudev Swami Chinmayanandaji
          were chanted as a medium to connect with his teachings and seek his
          blessings. The celebrations helped students gain a deeper
          understanding of their cultural heritage. The students learned the
          importance of showing respect and expressing gratitude to their gurus
          for their invaluable guidance and support.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
